define("sirvo-web/templates/popups/simple-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TRBndCTw",
    "block": "{\"symbols\":[\"modal\",\"footer\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHidden\",\"onSubmit\"],[[26,\"action\",[[21,0,[]],\"onCancel\"],null],[26,\"action\",[[21,0,[]],\"onSubmit\"],null]]],{\"statements\":[[4,\"if\",[[22,[\"title\"]]],null,{\"statements\":[[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"      \"],[6,\"h5\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n        \"],[1,[20,\"title\"],true],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,[\"message\"]]],null,{\"statements\":[[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"      \"],[6,\"div\"],[10,\"class\",\"align-center\"],[8],[1,[20,\"message\"],true],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[26,\"queue\",[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],[26,\"action\",[[21,0,[]],[21,1,[\"submit\"]]],null]],null],\"success\",\"xs\"]],{\"statements\":[[0,\"      Si\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"bs-button\",null,[[\"onClick\",\"type\",\"size\"],[[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null],\"danger\",\"xs\"]],{\"statements\":[[0,\"      No\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/popups/simple-confirm.hbs"
    }
  });

  _exports.default = _default;
});