define("sirvo-web/routes/inventario/movimientos/salidas/traslado/registro", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-movdet',
    parentRoute: 'inventario.movimientos.salidas.traslado',
    indexRoute: 'inventario.movimientos.salidas.traslado.detalles',
    routeRecordIdentifierDynamicSegment: 'detalle_id',
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)(true),
        movimiento: (0, _validators.validatePresence)(true),
        articulo: (0, _validators.validatePresence)(true),
        bodega: (0, _validators.validatePresence)(true),
        unidadMedida: (0, _validators.validatePresence)(true),
        cantidad: (0, _validators.validateNumber)({
          gt: 0
        }),
        bodegaDestino: (0, _validators.validatePresence)(true)
      };
    }),

    model() {
      let parentData = this.modelFor(this.parentRoute);

      if (!parentData.record.get('id')) {
        this.toast.error('Guarde el movimiento para poder registrar un detalle.');
        return this.transitionTo(`${this.parentRoute}.movimiento`);
      }

      return this._super(...arguments).then(modelData => {
        let movDet = modelData.record;

        if (!movDet.get('keymovdet')) {
          return Ember.merge(modelData, {
            bodegaDestino: null
          });
        }

        return Ember.RSVP.hash({
          bodegaDestino: this.store.query(this.modelName, {
            filter: {
              keyaux: movDet.get('keymovdet')
            },
            include: 'bodega'
          })
        }).then(({
          bodegaDestino
        }) => {
          if (!bodegaDestino.length) {
            this.toast.error(`El registro de salida del traslado no existe.`); // Destroy record if is new.

            movDet.rollbackAttributes();
            return this.transitionTo(this.indexRoute);
          }

          return Ember.merge(modelData, {
            bodegaDestino: bodegaDestino.get('firstObject').get('bodega')
          });
        });
      });
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        bodegaDestino: null
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let movDet = model.record;

      if (movDet.get('isNew')) {
        let parentData = this.modelFor(this.parentRoute);
        movDet.set('movimiento', parentData.record);
      }

      movDet.set('bodegaDestino', model.bodegaDestino);
    },

    actions: {
      willTransition(transition) {
        if (transition.targetName.startsWith(this.routeName)) {
          return false;
        }

        return this._super(transition);
      }

    }
  });

  _exports.default = _default;
});