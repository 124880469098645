define("sirvo-web/routes/dashboard/arrendamiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    request: Ember.inject.service(),
    loader: Ember.inject.service(),
    processor: Ember.inject.service('data-processor'),

    model() {
      this.loader.setIsLoading();
      return Ember.RSVP.hash({
        arrendamientosData: this.request.get('dashboard/arrendamiento/empresas'),
        localesData: this.request.get('dashboard/arrendamiento/locales')
      }).then(({
        arrendamientosData,
        localesData
      }) => {
        let modelData = Ember.merge(this.processor.prepareArrendamientosData(arrendamientosData), this.processor.prepareLocalesData(localesData));
        return Ember.RSVP.resolve(modelData);
      }).finally(() => this.loader.setNotLoading());
    },

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('currentYear', new Date().getFullYear());
    }

  });

  _exports.default = _default;
});