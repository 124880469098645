define("sirvo-web/models/gru-directorio-clasificacion", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    iddirclasifica: (0, _model.attr)('string'),
    // codgrupo: attr('string'),
    // coddirectorio: attr('string'),
    // codclasifica: attr('string'),
    cuentaRef: (0, _model.attr)('string'),
    fechaBaja: (0, _model.attr)('date'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),
    // Relationships
    grupo: (0, _model.belongsTo)('grupo'),
    directorio: (0, _model.belongsTo)('gru-directorio'),
    clasificacion: (0, _model.belongsTo)('gru-clasificacion')
  });

  _exports.default = _default;
});