define("sirvo-web/controllers/inventario/movimientos/salidas/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.movimientos.salidas'),
    indexRoute: null,
    noEsEditable: true,
    tipoDocumentoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editTipoDocumento.bind(this)
      }];
    }),
    proveedorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editProveedor.bind(this)
      }];
    }),
    autorizadoPorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editAutorizacion.bind(this)
      }];
    }),

    postSave(record) {
      if (this.indexCtrl) {
        this.indexCtrl.set('reload', true);
      }

      return this.transitionToRoute(`${this.indexRoute}.registro-detalle`, record.id, 'new');
    },

    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      },

      editTipoDocumento() {
        if (this.noEsEditable) {
          this.toast.warning('No se puede modificar este registro, cuenta con cierre de costo.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.tipo-doc`);
      },

      editProveedor() {
        if (this.noEsEditable) {
          this.toast.warning('No se puede modificar este registro, cuenta con cierre de costo.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.proveedor`);
      },

      editAutorizacion() {
        if (this.noEsEditable) {
          this.toast.warning('No se puede modificar este registro, cuenta con cierre de costo.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.autorizacion`);
      }

    }
  });

  _exports.default = _default;
});