define("sirvo-web/models/custom-inflector-rules", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    inflector
  } = _emberInflector.default;
  inflector.rules.irregular = [];
  inflector.rules.irregularInverse = [];
  var _default = {};
  _exports.default = _default;
});