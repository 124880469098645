define("sirvo-web/routes/inventario/movimientos/ingresos/record", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset", "ember-changeset-validations/validators", "ember-changeset-validations", "sirvo-web/validations/kar-movdet"], function (_exports, _abstractModuleRecord, _emberChangeset, _validators, _emberChangesetValidations, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.ingresos',
    validations: Ember.computed(function () {
      return {
        serie: (0, _validators.validatePresence)(true),
        numero: (0, _validators.validatePresence)(true),
        proveedor: (0, _validators.validatePresence)(true),
        fecha: (0, _validators.validatePresence)(true)
      };
    }),
    relatedModelsToQuery: Ember.computed('recordId', function () {
      let relatedModels = [];

      if (!this.isNew) {
        relatedModels.push({
          name: 'detalles',
          modelName: 'kar-movdet',
          queryParams: {
            filter: {
              movimiento: this.recordId
            },
            page_enabled: false
          }
        });
      } else {
        relatedModels.push({
          name: 'detalles',
          presetValue: Ember.A([])
        });
      }

      relatedModels.push({
        name: 'tipoMov',
        modelName: 'kar-tipomov',
        queryParams: {
          filter: {
            'tipomov': 'INGRESOS'
          }
        }
      }, {
        name: 'tipoDoc',
        modelName: 'kar-doc',
        queryParams: {
          filter: {
            empresa: this.company.id,
            'tipoMovimiento.tipomov': 'INGRESOS',
            'codtipo': {
              exact: 'FA'
            }
          }
        }
      });
      return relatedModels;
    }),

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        fecha: new Date()
      });
    },

    model() {
      return this._super(...arguments).then(modelData => {
        let promises = {
          maxFecha: this.request.get('inventario/max-cierre-costo', {
            empresaId: this.company.id
          })
        };
        return Ember.RSVP.hash(promises).then(({
          maxFecha
        }) => {
          return Ember.merge(modelData, {
            maxFecha: new Date(maxFecha.get('firstObject').fecha)
          });
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let lineaModel = this.store.createRecord('kar-movdet');
      let lineaChangeset = new _emberChangeset.default(lineaModel, (0, _emberChangesetValidations.default)(_karMovdet.default), _karMovdet.default);
      let noEsEditable = model.record.fecha ? model.maxFecha >= model.record.fecha : false;

      if (noEsEditable) {
        this.toast.warning('No se puede modificar este registro, cuenta con cierre de costo.');
      }

      controller.setProperties({
        items: model.detalles.toArray(),
        lineaModel: lineaModel,
        lineaChangeset: lineaChangeset,
        noEsEditable: noEsEditable
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});