define("sirvo-web/models/emp-periodo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codperiodo: (0, _model.attr)('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    nomenclatura: (0, _model.belongsTo)('com-def-nomenclatura'),
    feciniperiodo: (0, _model.attr)('date'),
    fecfinperiodo: (0, _model.attr)('date'),
    // periodoextraordinario: attr('number', { defaultValue: 0 }),
    // periodoinactivo: attr('number', { defaultValue: 0 }),
    // obsperiodo: attr('string'),
    // ivacr: attr('string'),
    // ivacrExt: attr('string'),
    // ivadb: attr('string'),
    // ivacombustible: attr('string'),
    // ivaRetencion: attr('string'),
    // ivaRetencionPorPagar: attr('string'),
    // ivaExencion: attr('string'),
    // isrcobrar: attr('string'),
    // isrpagar: attr('string'),
    // isrpagarNodomiciliadas: attr('string'),
    // isrpagarTerceros: attr('string'),
    // facespIva: attr('string'),
    // facespIsrCompras: attr('string'),
    // facespIsrServicios: attr('string'),
    // timbreprensa: attr('string'),
    // imptoturismo: attr('string'),
    // gananciaacumulada: attr('string'),
    // gananciaejercicio: attr('string'),
    // gananciacambiaria: attr('string'),
    // perdidacambiaria: attr('string'),
    // gtoxctaajena: attr('string'),
    // mesesepg: attr('number', { defaultValue: 12 }),
    usaccepg: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usacabg: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usacibg: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaciepg: (0, _model.attr)('number', {
      defaultValue: 0
    }) // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});