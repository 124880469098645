define("sirvo-web/router", ["exports", "sirvo-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('404', {
      path: '**'
    });
    this.route('login');
    this.route('logout');
    this.route('inventario', function () {
      this.route('movimientos', function () {
        this.route('ingresos', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('proveedor');
            this.route('articulo');
          });
        });
        this.route('salidas', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('tipo-doc');
            this.route('proveedor');
            this.route('autorizacion');
          });
          this.route('detalles', {
            path: '/:id/detalles'
          });
          this.route('registro-detalle', {
            path: '/:id/detalles/:detalle_id'
          }, function () {
            this.route('articulo');
            this.route('bodega');
            this.route('unidad-medida');
            this.route('lote');
            this.route('cuenta');
            this.route('centro-costo');
            this.route('centro-integracion');
          });
          this.route('traslado', {
            path: '/traslado/:id'
          }, function () {
            this.route('movimiento', function () {
              this.route('tipo-doc');
            });
            this.route('registro', {
              path: '/registro/:detalle_id'
            }, function () {
              this.route('articulo');
              this.route('bodega');
              this.route('unidad-medida');
              this.route('lote');
              this.route('bodega-destino');
            });
            this.route('detalles');
          });
        });
      });
      this.route('tablas', function () {
        this.route('articulos', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('datos-generales', function () {
              this.route('asignar-bodega');
            });
            this.route('registro');
            this.route('configuracion');
            this.route('unidades-medida');
          });
        });
        this.route('bodegas', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('datos-generales');
            this.route('configuraciones');
            this.route('dimensiones');
          });
        });
      });
    });
    this.route('dashboard', function () {
      this.route('contratos');
      this.route('arrendamiento');
      this.route('parqueos');
      this.route('resultados');
    });
    this.route('admon', function () {
      this.route('grupos', function () {
        this.route('contactos');
        this.route('directorios', function () {
          this.route('record', {
            path: '/:id'
          }, function () {
            this.route('generales');
            this.route('clasificaciones-contactos');
            this.route('informacion-fiscal');
            this.route('es-cliente');
            this.route('es-proveedor');
            this.route('datos-regionales');
          });
        });
        this.route('clasificaciones');
        this.route('directorios-clasificaciones');
      });
      this.route('directorio', function () {
        this.route('contactos');
      });
      this.route('maestros', function () {
        this.route('grupos');
        this.route('empresas');
      });
    });
    this.route('reporte-kardex');
    this.route('reporte-existencias');
  });
  var _default = Router;
  _exports.default = _default;
});