define("sirvo-web/controllers/inventario/movimientos/salidas/registro-detalle", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    parameters: Ember.computed.readOnly('currentCompany.parameters'),
    periodo: null,
    movimiento: null,
    bodegas: null,
    unidadesMedida: null,
    existenciaBase: 0,
    existencia: 0,
    tipoCuenta: null,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    bodegaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editBodega.bind(this)
      }];
    }),
    loteActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editLote.bind(this)
      }];
    }),
    medidaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editUnidadMedida.bind(this)
      }];
    }),
    cuentaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editCuenta.bind(this)
      }];
    }),
    centroCostoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editCentroCosto.bind(this)
      }];
    }),
    centroIntegracionActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editCentroIntegracion.bind(this)
      }];
    }),

    resetProperties() {
      this.setProperties({
        existenciaBase: 0,
        existencia: 0,
        tipoCuenta: null
      });
    },

    postSave() {
      if (!this.movimiento) {
        return;
      }

      return this.transitionToRoute(`${this.indexRoute}.detalles`, this.movimiento.id);
    },

    processSave(changeset) {
      if (this.existenciaBase - changeset.get('cantidadBase') < 0) {
        this.loader.setLoading(false);
        this.toast.error('No hay suficiente existencia del artículo seleccionado.');
        return Ember.RSVP.resolve(false);
      }

      return this._super(...arguments);
    },

    submit() {
      // TODO: Find a better way to get the current route instance.
      let route = this.target.currentState.routerJs.getHandler(this.routeName);

      if (this.changeset.get('articulo.usaLote')) {
        route.validations.lote = (0, _validators.validatePresence)(true);
      } else {
        delete route.validations.lote;
        delete this.changeset._errors.lote;
      }

      return this._super(...arguments);
    },

    actions: {
      onClose() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(this.indexRoute);
      },

      updateCantidad(changeset, properyName, value) {
        changeset.set(properyName, parseFloat(value) || 0);
        this.kardex.onChangesetCantidadUpdated(changeset);
      },

      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeUnidadMedida(uMedida) {
        return uMedida ? uMedida.get('codmedida') : null;
      },

      serializeCuenta(cuenta) {
        return cuenta ? cuenta.get('codcuenta') : null;
      },

      serializeCentroCosto(centroCosto) {
        return centroCosto ? centroCosto.get('codcc') : null;
      },

      serializeCentroIntegracion(centroIntegracion) {
        return centroIntegracion ? centroIntegracion.get('codci') : null;
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      },

      editBodega() {
        this.transitionToRoute(`${this.routeName}.bodega`);
      },

      editLote() {
        this.transitionToRoute(`${this.routeName}.lote`);
      },

      editUnidadMedida() {
        this.transitionToRoute(`${this.routeName}.unidad-medida`);
      },

      editCuenta() {
        this.transitionToRoute(`${this.routeName}.cuenta`);
      },

      editCentroCosto() {
        this.transitionToRoute(`${this.routeName}.centro-costo`);
      },

      editCentroIntegracion() {
        this.transitionToRoute(`${this.routeName}.centro-integracion`);
      },

      goToDetails() {
        this.changeset.rollback();
        this.record.rollbackAttributes();
        this.transitionToRoute(`${this.indexRoute}.detalles`, this.movimiento.id);
      }

    }
  });

  _exports.default = _default;
});