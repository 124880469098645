define("sirvo-web/models/emp-nomenclatura", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    empresa: (0, _model.belongsTo)('empresa'),
    periodo: (0, _model.belongsTo)('emp-periodo'),
    codcuenta: (0, _model.attr)('string'),
    nomcta: (0, _model.attr)('string') // nomcortocta: attr('string'),
    // codctamayor: attr('string'),
    // nivelcta: attr('number', { defaultValue: 0 }),
    // ultimonivelcta: attr('number', { defaultValue: 0 }),
    // usacabgcta: attr('number', { defaultValue: 0 }),
    // usacibgcta: attr('number', { defaultValue: 0 }),
    // usaauxiliarcta: attr('number', { defaultValue: 0 }),
    // codsistema: attr('string'),
    // codclasifica: attr('string'),
    // codctachq: attr('string'),
    // naturalezacta: attr('string'),
    // codctaalterna: attr('string'),
    // codctainternacional: attr('string'),
    // codccinternacional: attr('string'),
    // nombrectainternacional: attr('string'),
    // nombreprocesocta: attr('string'),
    // feccierrelogicocta: attr('date'),
    // ctainactiva: attr('string', { defaultValue: 0 }),
    // fechaBaja: attr('date'),
    // fechaCierreLogico: attr('date'),
    // estructura: attr('string', { defaultValue: 0 }),
    // obs: attr('string'),
    // obscta: attr('string'),
    // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});