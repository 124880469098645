define("sirvo-web/services/request", ["exports", "fetch", "ember-fetch/utils/serialize-query-params", "ember-fetch/errors", "sirvo-web/config/environment"], function (_exports, _fetch, _serializeQueryParams, _errors, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),

    _handleResponse(res) {
      if (res.ok) {
        let headers = res.headers && res.headers.map || res.headers;

        if (headers && headers['content-type'] === 'application/json') {
          return res.json();
        }

        return res;
      }

      if ((0, _errors.isBadRequestResponse)(res)) {
        this.toast.error('Solicitud incorrecta.');
      } else if ((0, _errors.isUnauthorizedResponse)(res)) {
        this.toast.error('Esta operación requiere de autenticación.');
      } else if ((0, _errors.isForbiddenResponse)(res)) {
        this.toast.error('No tiene permiso para realizar esta operación.');
      } else if (_errors.isNotFoundResponse) {
        this.toast.error('Registro no encontrado.');
      } else if ((0, _errors.isInvalidResponse)(res)) {
        this.toast.error('Hay un error de validación.');
      } else if ((0, _errors.isServerErrorResponse)(res)) {
        this.toast.error('En este momento no se puede procesar su solicitud.');
      }

      throw res;
    },

    async fetch(url, options = {}) {
      let {
        token
      } = this.session.get('data.authenticated');
      let {
        host,
        namespace
      } = _environment.default.API;
      let reqOptions = options;

      if (!options.anonymous) {
        reqOptions = Ember.merge(options, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }

      return (0, _fetch.default)(`${host}/${namespace}/${url}`, reqOptions).then(this._handleResponse.bind(this)).catch(error => {
        this.toast.error('Ocurrió un error con su solicitud.');
        throw error;
      });
    },

    async get(url, params = null, options = {}) {
      if (!params) {
        return this.fetch(url, options);
      }

      const paramsString = (0, _serializeQueryParams.default)(params);
      return this.fetch(`${url}?${paramsString}`, options);
    },

    async post(url, data = {}, options = {}) {
      let reqOptions = Ember.merge({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }, options);
      return this.fetch(url, reqOptions);
    }

  });

  _exports.default = _default;
});