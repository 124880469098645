define("sirvo-web/routes/inventario/movimientos/salidas/record/autorizacion", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let modelData = this._super(...arguments);

      return Ember.RSVP.hash({
        empleados: this.store.query('gru-directorio', {
          filter: {
            grupo: this.company.get('grupo.id'),
            clasificacion: 'EMPLEADOS',
            fechabaja: 'NULL'
          }
        })
      }).then(routeData => Ember.merge(modelData, routeData));
    }

  });

  _exports.default = _default;
});