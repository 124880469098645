define("sirvo-web/templates/inventario/tablas/articulos/record/datos-generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pJUggq7+",
    "block": "{\"symbols\":[\"f\",\"tipoArticulo\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"correlativo\",\"Código principal\"],[[\"placeholder\",\"disabled\",\"hint\"],[\"Código principal\",[21,0,[\"parameters\",\"codigoArticulosGen\"]],[26,\"if\",[[21,0,[\"parameters\",\"codigoArticulosGen\"]],\"Autogenerado por el sistema\"],null]]]],false],[0,\"\\n\\n\"],[4,\"component\",[[21,1,[\"ace-power-select\"]],\"tipoArticulo\",\"Famila/Grupo:\"],[[\"selected\",\"options\",\"placeholder\",\"searchField\",\"onchange\"],[[21,0,[\"changeset\",\"tipoArticulo\"]],[21,0,[\"tiposArticulos\"]],\"Seleccionar...\",\"codtipoart\",[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"tipoArticulo\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,2,[\"codtipoart\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"descripcion\",\"Descripción\"],[[\"placeholder\"],[\"Descripción\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codmedidaBase\",\"Unidad de medida base\"],[[\"placeholder\",\"labelSize\",\"widgetSize\"],[\"Unidad de medida base\",\"sm-6\",\"sm-6\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/articulos/record/datos-generales.hbs"
    }
  });

  _exports.default = _default;
});