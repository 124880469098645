define("sirvo-web/controllers/inventario/movimientos/ingresos/record", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset", "ember-changeset-validations", "ember-concurrency", "sirvo-web/validations/kar-movdet"], function (_exports, _abstractModuleRecord, _emberChangeset, _emberChangesetValidations, _emberConcurrency, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    loader: Ember.inject.service(),
    toast: Ember.inject.service(),
    indexRoute: null,
    lineaModel: null,
    lineaChangeset: null,
    noEsEditable: true,
    proveedorActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editProveedor.bind(this)
      }];
    }),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    guardarDetalle: (0, _emberConcurrency.task)(function* (detalle) {
      yield detalle.save();
      return;
    }).enqueue(),
    columns: Ember.computed(function () {
      return [{
        label: 'Articulo',
        valuePath: 'articulo.codarticulo',
        filtrable: true,
        filterName: 'articulo',
        sortable: false,
        width: '100px'
      }, {
        label: 'Descripción',
        valuePath: 'articulo.descripcion',
        filtrable: true,
        filterName: 'articulo.descripcion',
        sortable: false,
        width: '100px'
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        filtrable: true,
        filterName: 'cantidad',
        sortable: false,
        width: '100px'
      }, {
        label: 'U/Medida',
        valuePath: 'unidadMedida.codmedida',
        filtrable: true,
        filterName: 'unidadMedida.codmedida',
        sortable: false,
        width: '100px'
      }, {
        label: 'Precio',
        valuePath: 'precioUnitario',
        filtrable: true,
        filterName: 'precioUnitario',
        sortable: false,
        width: '100px'
      }, {
        label: 'Costo',
        valuePath: 'total',
        filtrable: true,
        filterName: 'total',
        sortable: false,
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.deleteItem.bind(this)
          }
        }
      }];
    }),
    actions: {
      onClose() {
        this.record.rollbackAttributes();
        this.transitionToRoute(this.get('indexRoute'));
      },

      deleteItem(item) {
        item.deleteRecord();
        return item.save();
      },

      addItem() {
        return this.get('lineaChangeset').validate().then(() => {
          if (this.get('lineaChangeset').get('isInvalid')) {
            this.toast.error('Hay un problema en la forma de la linea');
            return;
          }

          this.changeset.execute();
          this.get('lineaChangeset').set('movimiento', this.get('record'));
          this.get('lineaChangeset').set('empresa', this.currentCompany.get('company'));
          this.get('lineaChangeset').set('unidadMedida', this.get('lineaChangeset.articulo.unidadesMedida.firstObject'));
          this.get('lineaChangeset').set('cantidadBase', this.get('lineaChangeset').get('cantidad'));
          this.get('lineaChangeset').set('subtotal', this.get('lineaChangeset').get('total'));
          this.get('lineaChangeset').set('valorNeto', this.get('lineaChangeset').get('total'));
          this.get('lineaChangeset').set('linea', this.items.length + 1);
          this.get('lineaChangeset').execute();
          this.get('items').pushObject(this.get('lineaModel'));
          this.set('lineaModel', this.store.createRecord('kar-movdet'));
          this.set('lineaChangeset', new _emberChangeset.default(this.get('lineaModel'), (0, _emberChangesetValidations.default)(_karMovdet.default), _karMovdet.default));
          this.toast.success('Detalle agregado correctamente');
        });
      },

      changePrecioOCantidad(changeset, name, value) {
        changeset.set(name, value);
        let total = parseFloat(changeset.get('precioUnitario')) * parseFloat(changeset.get('cantidad'));

        if (!Number.isNaN(total)) {
          changeset.set('total', total);
        }
      },

      submit() {
        if (!this.items) {
          this.toast.error('Debe guardar al menos un ingreso.');
          return;
        }

        return this.get('changeset').validate().then(() => {
          if (this.get('changeset').get('isInvalid')) {
            this.toast.error('Hay un problema en la forma de la linea');
            return;
          }

          this.loader.setIsLoading();
          let valordocml = this.items.mapBy('total').reduce((a, b) => a + b);
          this.changeset.set('tipoMovimiento', this.get('tipoMov.firstObject'));
          this.changeset.set('tipoDocumento', this.get('tipoDoc.firstObject'));
          this.changeset.set('deha', 'D');
          this.changeset.set('usacnt', true);
          this.changeset.set('valordocml', valordocml);
          this.changeset.set('valorNeto', valordocml);
          this.changeset.set('grupo', this.currentCompany.company.get('grupo')); // El calculo del subtotal y el iva deberia realizarle en el backend?

          return this.changeset.save().then(() => {
            this.items.forEach(item => {
              this.guardarDetalle.perform(item); // EN TEORIA DEBERIA REALIZAR ENQUEUE
            });
            this.toast.success('Registro almacenado correctamente.');
          }).catch(() => this.toast.error('Ocurrio un error al guardar el registro')).finally(() => this.loader.setNotLoading());
        });
      },

      editProveedor() {
        if (this.noEsEditable) {
          this.toast.warning('No se puede modificar este registro, cuenta con cierre de costo.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.proveedor`);
      },

      editArticulo() {
        if (this.noEsEditable) {
          this.toast.warning('No se puede modificar este registro, cuenta con cierre de costo.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.articulo`);
      }

    }
  });

  _exports.default = _default;
});