define("sirvo-web/routes/inventario/tablas/articulos/record/unidades-medida", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    setupController(controller, model) {
      this._super(controller, model);

      let changeset = controller.changeset;
      let codmedidaBase = changeset.get('codmedidaBase') ? changeset.get('codmedidaBase').trim() : null; // Crear una unidad de medida con la unidad base.

      if (codmedidaBase) {
        let unidadMedidaBase = model.record.get('unidadesMedida').filterBy('codmedida') // Ignore undefined.
        .find(uMedida => uMedida.get('codmedida').trim() === codmedidaBase);

        if (!unidadMedidaBase) {
          this.store.createRecord('kar-tabla-medida', {
            empresa: this.company,
            articulo: controller.record,
            codmedida: codmedidaBase,
            cantidad: 1,
            isValidated: true
          });
        }
      }

      controller.createNewChangeset();
    },

    actions: {
      willTransition() {
        this.controller.destroyCurrentRecord();
        return true;
      }

    }
  });

  _exports.default = _default;
});