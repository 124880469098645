define("sirvo-web/controllers/reporte-existencias", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    currentMonthIndex: Ember.computed(function () {
      let now = new Date();
      return now.getMonth();
    }),
    previousMonthIndex: Ember.computed(function () {
      let now = new Date();
      now.setMonth(now.getMonth() - 1);
      return now.getMonth();
    }),

    getSerializedFilters() {
      let endDate = this.changeset.get('endDate');
      let filters = {
        bodega: this.changeset.get('bodega.id'),
        articulo: this.changeset.get('articulo.id'),
        endDate: endDate ? (0, _moment.default)(endDate).format() : null
      };
      return filters;
    },

    actions: {
      searchArticulo(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.articleQuery) {
          Ember.run.cancel(this.articleQuery);
        }

        this.articleQuery = Ember.run.later(() => {
          this.set('availableArticles', this.store.query('kar-articulo', {
            filter: {
              descripcion: text,
              empresa: this.company.id
            }
          }));
        }, 500);
      }

    }
  });

  _exports.default = _default;
});