define("sirvo-web/templates/inventario/movimientos/salidas/registro-detalle/lote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KLr83Pxg",
    "block": "{\"symbols\":[\"modal\"],\"statements\":[[4,\"bs-modal\",null,[[\"size\",\"onHide\"],[\"lg\",[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[26,\"tables/simple-table\",null,[[\"model\",\"columns\"],[[21,0,[\"model\",\"existenciaPorLotes\"]],[21,0,[\"columns\"]]]]],false],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"Cerrar\"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/salidas/registro-detalle/lote.hbs"
    }
  });

  _exports.default = _default;
});