define("sirvo-web/templates/components/tables/cell/color-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VT29SU5R",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"style\",\"text-align: center;\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"value\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"fa-icon\",null,[[\"icon\",\"__ANGLE_ATTRS__\"],[\"circle\",[26,\"hash\",null,[[\"style\"],[[26,\"concat\",[\"color: \",[22,[\"color\"]],\";\"],null]]]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/components/tables/cell/color-mark.hbs"
    }
  });

  _exports.default = _default;
});