define("sirvo-web/controllers/inventario/movimientos/ingresos", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-mov", "sirvo-web/utils/date"], function (_exports, _abstractModuleIndex, _karMov, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    editRoute: 'inventario.movimientos.ingresos.record',
    modelName: 'kar-mov',
    modelClass: _karMov.default,
    reload: true,
    currentYear: Ember.computed(function () {
      return new Date().getFullYear();
    }),
    currentMonth: Ember.computed(function () {
      return String(new Date().getMonth() + 1).padStart(2, '0');
    }),
    currentYearMonth: Ember.computed('currentYear', 'currentMonth', function () {
      return `${this.currentYear}${this.currentMonth}`;
    }),
    years: (0, _date.lastYears)(),
    months: _date.monthIndexes,
    routeName: Ember.computed(function () {
      return this.router.currentRouteName.split('.').slice(0, -1).join('.');
    }),
    modelFilters: Ember.computed('currentYearMonth', function () {
      return [{
        selectedFilter: {
          filterName: 'empresa'
        },
        value: this.company.get('id')
      }, {
        selectedFilter: {
          filterName: 'deha'
        },
        value: {
          exact: 'D'
        }
      }, {
        selectedFilter: {
          filterName: 'tipoMovimiento.tipomov'
        },
        value: {
          exact: 'INGRESOS'
        }
      }, {
        selectedFilter: {
          filterName: 'yearmes'
        },
        value: {
          exact: this.currentYearMonth
        }
      }];
    }),
    modelSortingProperties: Ember.computed(function () {
      return [{
        propertyName: 'fecha'
      }, {
        propertyName: 'numero'
      }];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Fecha',
        valuePath: 'fechaFormatted',
        width: '100px'
      }, {
        label: 'Tipo Doc.',
        valuePath: 'tipoDocumento.codtipo',
        width: '100px'
      }, {
        label: 'Serie',
        valuePath: 'serie',
        width: '100px'
      }, {
        label: 'Numero',
        valuePath: 'numero',
        width: '100px'
      }, {
        label: 'Valor',
        valuePath: 'valordocml',
        width: '100px'
      }, {
        label: 'Movimiento',
        valuePath: 'tipoMovimiento.tipomov',
        width: '100px'
      }, {
        label: 'Descripción',
        valuePath: 'descripcion',
        width: '100px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    actions: {
      addRecord() {
        this.transitionToRoute(this.get('editRoute'), 'new');
      },

      delete(item, row, lightTable) {
        if (!item || !item.id) {
          return;
        }

        const superRef = this._super.bind(this);

        this.loader.setLoading(true);
        return this.store.query('kar-movdet', {
          filter: {
            movimiento: item.id
          }
        }).then(detalles => {
          if (!detalles.length) {
            return superRef(item, row, lightTable);
          }

          this.toast.error('El movimiento posee detalles.');
        }).finally(() => this.loader.setLoading(false));
      },

      reloadTable() {
        this.set('reload', true);
      }

    }
  });

  _exports.default = _default;
});