define("sirvo-web/templates/admon/grupos/directorios/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xwyEytyN",
    "block": "{\"symbols\":[],\"statements\":[[6,\"div\"],[10,\"class\",\"w-100 bg-white border-bottom px-4\"],[8],[0,\"\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-12 py-4\"],[8],[0,\"\\n      \"],[6,\"h3\"],[8],[0,\"\\n        Directorios\\n      \"],[9],[0,\"\\n\\n      \"],[6,\"div\"],[10,\"class\",\"float-right\"],[8],[0,\"\\n\"],[4,\"link-to\",[\"admon.grupos.directorios.record\",\"new\"],[[\"class\"],[\"btn btn-success btn-xs\"]],{\"statements\":[[0,\"          \"],[6,\"i\"],[10,\"class\",\"fa fa-plus-circle\"],[10,\"aria-hidden\",\"true\"],[8],[9],[0,\"\\n          Agregar\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col-xs-12\"],[8],[0,\"\\n      \"],[1,[26,\"tables/paginated-table\",null,[[\"columns\",\"modelName\",\"modelClassObject\",\"modelFilters\",\"load\",\"pageSize\",\"tableClassNames\"],[[21,0,[\"columns\"]],[21,0,[\"modelName\"]],[21,0,[\"modelClass\"]],[21,0,[\"modelFilters\"]],[21,0,[\"reload\"]],10,\"ember-table thead-gray\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[20,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/admon/grupos/directorios/index.hbs"
    }
  });

  _exports.default = _default;
});