define("sirvo-web/controllers/inventario/movimientos/salidas/traslado/registro/lote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    detalleCtrl: Ember.inject.controller('inventario.movimientos.salidas.traslado.registro'),
    selectedLote: null,
    columns: Ember.computed('selectedLote', function () {
      return [{
        label: '',
        width: '60px',
        align: 'center',
        sortable: false,
        cellComponent: 'tables/row/select-row-radio-button',
        table: this,
        groupValue: this.selectedLote,
        onSelectRow: this.actions.selectLote.bind(this)
      }, {
        label: 'Lote',
        valuePath: 'lote',
        width: '80px',
        sortable: false
      }, {
        label: 'Existencia',
        valuePath: 'existencia',
        width: '110px',
        sortable: false
      }];
    }),
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      selectLote(selectedLote) {
        this.set('selectedLote', selectedLote);
        this.changeset.set('lote', selectedLote.lote);

        if (selectedLote) {
          let unidadMedidaCantidad = this.changeset.get('unidadMedida.cantidad') || 0;
          this.detalleCtrl.setProperties({
            existenciaBase: selectedLote.existencia,
            existencia: selectedLote.existencia / (unidadMedidaCantidad || 1)
          });
        }
      }

    }
  });

  _exports.default = _default;
});