define("sirvo-web/components/app-sidebar", ["exports", "sirvo-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: Ember.computed.readOnly('currentUser.user'),
    tagName: '',
    isNavbarCollapsed: false,
    sidebarMenus: Ember.computed(function () {
      let menus = Ember.A();

      if (_environment.default.APP.MODULES.GENERAL) {
        menus.pushObject({
          title: 'ADMON. GENERAL',
          children: [{
            title: 'Maestros',
            children: [{
              title: 'Grupos',
              route: 'admon.maestros.grupos'
            }, {
              title: 'Empresas',
              route: 'admon.maestros.empresas'
            }]
          }, {
            title: 'Grupos',
            children: [{
              title: 'Directorios',
              route: 'admon.grupos.directorios'
            }, {
              title: 'Clasificaciones',
              route: 'admon.grupos.clasificaciones'
            }, {
              title: 'Directorios por clasificación',
              route: 'admon.grupos.directorios-clasificaciones'
            }, {
              title: 'Contactos',
              route: 'admon.grupos.contactos'
            }, {
              title: 'Contactos de Directorio',
              route: 'admon.directorio.contactos'
            }]
          }]
        });
      }

      if (_environment.default.APP.MODULES.INVENTARIO) {
        menus.pushObject({
          title: 'INVENTARIO',
          children: [{
            title: 'Movimientos',
            children: [{
              title: 'Ingresos',
              route: 'inventario.movimientos.ingresos'
            }, {
              title: 'Salidas',
              route: 'inventario.movimientos.salidas'
            }]
          }, {
            title: 'Tablas',
            children: [{
              title: 'Bodegas',
              route: 'inventario.tablas.bodegas'
            }, {
              title: 'Artículos',
              route: 'inventario.tablas.articulos'
            }]
          }, {
            title: 'Reportes',
            children: [{
              title: 'Kardex',
              route: 'reporte-kardex'
            }, {
              title: 'Existencias',
              route: 'reporte-existencias'
            }]
          }]
        });
      }

      if (_environment.default.APP.MODULES.DASHBOARD) {
        menus.pushObject({
          title: 'DASHBOARD',
          route: 'dashboard'
        });
      }

      return menus;
    }),
    actions: {
      toggleNavbar() {
        this.set('isNavbarCollapsed', !this.isNavbarCollapsed);
      }

    }
  });

  _exports.default = _default;
});