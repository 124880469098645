define("sirvo-web/routes/inventario/movimientos/salidas/detalles", ["exports", "servir-ember-utilities/routes/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-mov',
    indexRoute: 'inventario.movimientos.salidas',
    validations: Ember.computed(function () {
      return {};
    }),

    model() {
      return this._super(...arguments).then(modelData => {
        let promises = {
          maxFecha: this.request.get('inventario/max-cierre-costo', {
            empresaId: this.company.id
          })
        };
        return Ember.RSVP.hash(promises).then(({
          maxFecha
        }) => {
          return Ember.merge(modelData, {
            maxFecha: new Date(maxFecha.get('firstObject').fecha)
          });
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      let noEsEditable = model.record.fecha ? model.maxFecha >= model.record.fecha : false;

      if (noEsEditable) {
        this.toast.warning('No se puede modificar este registro, cuenta con cierre de costo.');
      }

      controller.setProperties({
        noEsEditable: noEsEditable
      });
    }

  });

  _exports.default = _default;
});