define("sirvo-web/controllers/inventario/movimientos/salidas/registro-detalle/bodega", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    detalleCtrl: Ember.inject.controller('inventario.movimientos.salidas.registro-detalle'),
    kardex: Ember.inject.service(),
    actions: {
      bodegaUpdated() {
        this.kardex.onChangesetBodegaUpdated(this.changeset, this.detalleCtrl);
      },

      onClose() {
        this.transitionToRoute(this.parentRoute);
      }

    }
  });

  _exports.default = _default;
});