define("sirvo-web/routes/inventario/movimientos/ingresos/record/articulo", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let modelData = this._super(...arguments);

      return Ember.RSVP.hash({
        articulos: this.store.query('kar-articulo', {
          filter: {
            empresa: this.company.get('id'),
            fechabaja: 'NULL',
            page_enabled: false
          },
          'fields[articulos]': 'codarticulo,descripcion',
          sort: 'descripcion'
        })
      }).then(routeData => Ember.merge(modelData, routeData));
    },

    setupController(controller, model) {
      this._super(controller, model);

      let lineaChangeset = this.controllerFor(this.parentRoute).get('lineaChangeset');
      controller.set('lineaChangeset', lineaChangeset);
    }

  });

  _exports.default = _default;
});