define("sirvo-web/models/kar-doc", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codtipo: (0, _model.attr)('string'),
    empresa: (0, _model.belongsTo)('empresa'),
    codsistema: (0, _model.attr)('string'),
    tipoMovimiento: (0, _model.belongsTo)('kar-tipomov'),
    deha: (0, _model.attr)('string'),
    correlativoAutomatico: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    correlativoAnioMes: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaCnt: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // nomformato: attr('string'),
    // anchoInches: attr('number'),
    // largoInches: attr('number'),
    serie: (0, _model.attr)('string'),
    numero: (0, _model.attr)('number') // creusr: attr('string'),
    // fecusr: attr('date'),
    // codusr: attr('string'),

  });

  _exports.default = _default;
});