define("sirvo-web/services/kardex", ["exports", "ember-changeset/utils/is-changeset"], function (_exports, _isChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    request: Ember.inject.service(),
    loader: Ember.inject.service(),

    onChangesetArticuloUpdated(changeset, indexCtrl = null) {
      if (!(0, _isChangeset.default)(changeset)) {
        throw Error('An instance of Changeset expected.');
      }

      let articuloId = changeset.get('articulo.id');

      if (!articuloId) {
        Ember.setProperties(changeset, {
          bodega: null,
          unidadMedida: null,
          lote: null,
          precioUnitario: 0,
          subtotal: 0,
          valorNeto: 0,
          total: 0,
          cantidadBase: 0
        });
        return;
      }

      this.loader.setLoading(true);
      return Ember.RSVP.hash({
        articuloBodegas: this.store.query('kar-bodega-det', {
          filter: {
            articulo: articuloId,
            habilitado: 1
          },
          include: 'bodega'
        }),
        unidadesMedida: this.store.query('kar-tabla-medida', {
          filter: {
            articulo: articuloId,
            venta: 1
          }
        })
      }).then(({
        articuloBodegas,
        unidadesMedida
      }) => {
        let bodegas = articuloBodegas.mapBy('bodega');

        if (indexCtrl) {
          indexCtrl.setProperties({
            bodegas,
            unidadesMedida
          });
        }

        Ember.setProperties(changeset, {
          bodega: bodegas.get('firstObject') || null,
          unidadMedida: unidadesMedida.get('firstObject') || null,
          lote: null
        });

        if (!changeset.get('bodega.id')) {
          return;
        }

        this.onChangesetBodegaUpdated(changeset, indexCtrl);
      }).finally(() => this.loader.setLoading(false));
    },

    onChangesetBodegaUpdated(changeset, indexCtrl) {
      if (!(0, _isChangeset.default)(changeset)) {
        throw Error('An instance of Changeset expected.');
      }

      let articuloId = changeset.get('articulo.id');

      if (!articuloId || !changeset.get('bodega.id')) {
        Ember.setProperties(changeset, {
          precioUnitario: 0,
          subtotal: 0,
          valorNeto: 0,
          total: 0,
          cantidadBase: 0
        });
        return;
      }

      this.loader.setLoading(true);
      this.request.get(`kar-articulos/${articuloId}/saldo`, {
        bodegaId: changeset.get('bodega.id'),
        lote: changeset.get('lote') || null
      }).then(articuloSaldo => {
        let unidadMedidaCantidad = changeset.get('unidadMedida.cantidad') || 0;
        let precioUnitario = articuloSaldo['valor_promedio'];
        let totalNeto = changeset.get('cantidad') * precioUnitario || 0;
        let existencia = articuloSaldo['existencia'];

        if (changeset.get('articulo').get('usaLote') && changeset.get('lote')) {
          existencia = articuloSaldo['existencia_por_lote'];
        }

        if (indexCtrl) {
          indexCtrl.setProperties({
            existenciaBase: existencia,
            existencia: existencia / (unidadMedidaCantidad || 1)
          });
        }

        Ember.setProperties(changeset, {
          precioUnitario,
          subtotal: totalNeto,
          valorNeto: totalNeto,
          total: totalNeto,
          // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.
          cantidadBase: changeset.get('cantidad') * unidadMedidaCantidad
        });
      }).finally(() => this.loader.setLoading(false));
    },

    onChangesetUnidadMedidaUpdated(changeset, indexCtrl = null) {
      if (!(0, _isChangeset.default)(changeset)) {
        throw Error('An instance of Changeset expected.');
      }

      let unidadMedidaCantidad = changeset.get('unidadMedida.cantidad') || 0; // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.

      changeset.set('cantidadBase', changeset.get('cantidad') * unidadMedidaCantidad);
      indexCtrl.set('existencia', indexCtrl.existenciaBase / (unidadMedidaCantidad || 1));
    },

    onChangesetCantidadUpdated(changeset) {
      let totalNeto = changeset.get('cantidad') * changeset.get('precioUnitario') || 0;
      let unidadMedidaCantidad = changeset.get('unidadMedida.cantidad') || 0;
      Ember.setProperties(changeset, {
        subtotal: totalNeto,
        valorNeto: totalNeto,
        total: totalNeto,
        // WARNING: Este valor se sobre escribe en el backend para asegurar la consistencia.
        cantidadBase: changeset.get('cantidad') * unidadMedidaCantidad
      });
    }

  });

  _exports.default = _default;
});