define("sirvo-web/routes/reporte-kardex", ["exports", "ember-changeset-validations/validators", "servir-ember-utilities/routes/abstract-module-index", "sirvo-web/mixins/sirvo-web-file-saver", "ember-changeset-validations", "ember-changeset", "moment"], function (_exports, _validators, _abstractModuleIndex, _sirvoWebFileSaver, _emberChangesetValidations, _emberChangeset, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hash
  } = Ember.RSVP;

  var _default = _abstractModuleIndex.default.extend(_sirvoWebFileSaver.default, {
    company: Ember.computed.readOnly('currentCompany.company'),
    currentUser: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    breadcrumbsRoute: Ember.computed(function () {
      return [{
        name: 'Reporte kardex',
        route: 'reporte-kardex'
      }];
    }),
    validations: Ember.computed(function () {
      return {
        startDate: (0, _validators.validatePresence)({
          presence: true
        }),
        endDate: (0, _validators.validatePresence)({
          presence: true
        })
      };
    }),

    model()
    /*params, transition*/
    {
      this.loader.setIsLoading();
      let promises = {
        bodegas: this.store.query('kar-bodega', {
          filter: {
            empresa: this.company.id
          }
        }),
        articulos: this.store.query('kar-articulo', {
          filter: {
            empresa: this.company.id
          }
        })
      };
      return hash(promises).then(data => {
        let filterModel = this.store.createRecord('reporte-kardex');
        return Ember.merge(data, {
          filterModel
        });
      });
    },

    setupController(controller, model) {
      this._super(controller, model);

      this.loader.setNotLoading();
      let changeset = new _emberChangeset.default(model.filterModel, (0, _emberChangesetValidations.default)(this.get('validations')), this.get('validations'));
      controller.set('mesAnteriorSeleccionado', false);
      controller.set('mesActualSeleccionado', false);
      controller.set('anioActualSeleccionado', false);
      controller.set('changeset', changeset);
    },

    actions: {
      setDateByMonthIndex(monthIndex, mes) {
        if (monthIndex < 0 || monthIndex > 11) {
          throw Error('Invalid month index provided.');
        }

        let changeset = this.controller.get('changeset');
        let startDate = (0, _moment.default)();
        let endDate = (0, _moment.default)();

        if ((0, _moment.default)(startDate).month() === 0 && mes === 'anterior') {
          startDate.set('year', (0, _moment.default)(startDate).year() - 1);
          endDate.set('year', (0, _moment.default)(endDate).year() - 1);
        }

        startDate.month(monthIndex).startOf('month');
        endDate.month(monthIndex).endOf('month');

        if (mes === 'anterior') {
          changeset.set('mesActualSeleccionado', false);
          changeset.set('mesAnteriorSeleccionado', true);
        } else {
          changeset.set('mesActualSeleccionado', true);
          changeset.set('mesAnteriorSeleccionado', false);
        }

        changeset.set('anioActualSeleccionado', false);
        changeset.set('startDate', startDate.toDate());
        changeset.set('endDate', endDate.toDate());
      },

      setDateByYear() {
        let changeset = this.controller.get('changeset');
        let startDate = (0, _moment.default)();
        let endDate = (0, _moment.default)();
        startDate.startOf('year');
        endDate.endOf('year');
        changeset.set('mesAnteriorSeleccionado', false);
        changeset.set('mesActualSeleccionado', false);
        changeset.set('anioActualSeleccionado', true);
        changeset.set('startDate', startDate.toDate());
        changeset.set('endDate', endDate.toDate());
      },

      generarReporte() {
        this.loader.setIsLoading();
        let changeset = this.controller.get('changeset');
        return changeset.validate().then(() => {
          if (!changeset.get('isValid')) {
            this.loader.setNotLoading();
            this.toast.error('Debe seleccionar fecha inicio y fecha fin.');
            return;
          }

          const filters = this.controller.getSerializedFilters();

          if (!filters) {
            this.loader.setNotLoading();
            return;
          }

          this.getFileFromServer('/api/reporte-kardex', filters);
        });
      }

    }
  });

  _exports.default = _default;
});