define("sirvo-web/templates/inventario/tablas/articulos/record/datos-generales/asignar-bodega", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d/My0tZF",
    "block": "{\"symbols\":[\"modal\",\"f\"],\"statements\":[[4,\"bs-modal\",null,[[\"onHide\"],[[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"header\"]]],null,{\"statements\":[[0,\"\\n    \"],[6,\"h4\"],[10,\"class\",\"modal-title\"],[8],[0,\"\\n      Asignar a bodega\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"extended-form-for\",[[21,0,[\"bodegasProxy\"]]],null,{\"statements\":[[0,\"      \"],[1,[26,\"component\",[[21,2,[\"ace-multiselect-checkboxes\"]],\"bodegas\"],[[\"label\",\"options\",\"labelProperty\",\"selection\",\"onchange\"],[\"Bodegas\",[21,0,[\"bodegas\"]],\"descripcion\",[21,0,[\"bodegasProxy\",\"bodegas\"]],[26,\"action\",[[21,0,[]],\"updateBodegas\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]},null],[0,\"\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"Cancelar\"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[1,[26,\"forms/controls/ace-async-save-button\",null,[[\"onClick\"],[[26,\"action\",[[21,0,[]],\"onSubmit\"],null]]]],false],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/articulos/record/datos-generales/asignar-bodega.hbs"
    }
  });

  _exports.default = _default;
});