define("sirvo-web/controllers/inventario/movimientos/salidas/traslado/detalles", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/kar-movdet"], function (_exports, _abstractModuleIndex, _karMovdet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClass: _karMovdet.default,
    editRoute: Ember.computed('parentRoute', function () {
      return `${this.parentRoute}.registro`;
    }),
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'movimiento'
        },
        value: this.record.get('id') || -1
      }];
    }),
    includedRelationships: Ember.computed(function () {
      return ['articulo', 'unidadMedida', 'bodega'];
    }),
    columns: Ember.computed(function () {
      return [{
        label: 'Artículo',
        valuePath: 'articulo.codarticulo',
        width: '110px'
      }, {
        label: 'Nombre',
        valuePath: 'articulo.descripcion',
        width: '200px'
      }, {
        label: 'Cantidad',
        valuePath: 'cantidad',
        width: '90px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          edit: {
            title: 'Consultar',
            buttonClass: 'btn-primary',
            icon: 'eye',
            action: this.actions.edit.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    }),
    detalles: Ember.computed(function () {
      return Ember.A([]);
    }),
    actions: {
      addDetail() {
        if (this.record.get('isNew')) {
          this.toast.error('Guarde la cabecera del movimiento para poder registrar un traslado.');
          return;
        }

        if (!this.record.id) {
          return;
        }

        this.transitionToRoute(this.editRoute, this.record.id, 'new');
      },

      edit(detalle) {
        if (!this.record.id || !detalle.id) {
          return;
        }

        this.transitionToRoute(this.editRoute, this.record.id, detalle.id);
      }

    }
  });

  _exports.default = _default;
});