define("sirvo-web/models/kar-parametro", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    // codempresa: attr('string'),
    codigoArticulosGen: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // usaCodificacionArticulos: attr('number', { defaultValue: 0 }),
    // usaAutorizacionOrdenesC: attr('number', { defaultValue: 0 }),
    // usaOrdendTrabajo: attr('number', { defaultValue: 0 }),
    // usaPermisosReq: attr('number', { defaultValue: 0 }),
    // usaCtaCostoPor: attr('number', { defaultValue: 0 }),
    // usaRequisiciones: attr('number', { defaultValue: 1 }),
    // usaInventarioProcesoNocontable: attr('number', { defaultValue: 1 }),
    // validanombrearticulo: attr('number', { defaultValue: 1 }),
    // validarExistenciasSalidas: attr('number', { defaultValue: 1 }),
    // codigoprocedimiento: attr('string'),
    // nombrelote: attr('string'),
    // car1: attr('string'),
    // car2: attr('string'),
    // car3: attr('string'),
    // car4: attr('string'),
    // car5: attr('string'),
    // obligatorio1: attr('number', { defaultValue: 0 }),
    // obligatorio2: attr('number', { defaultValue: 0 }),
    // obligatorio3: attr('number', { defaultValue: 0 }),
    // obligatorio4: attr('number', { defaultValue: 0 }),
    // obligatorio5: attr('number', { defaultValue: 0 }),
    // usaLote: attr('number', { defaultValue: 0 }),
    // usaProcesoImportaciones: attr('number', { defaultValue: 0 }),
    // usaInventarioProceso: attr('number', { defaultValue: 1 }),
    // fechaOperaciones: attr('date'),
    // porcentajeMinGanancia: attr('number'),
    // porcentajeMaxGanancia: attr('number'),
    // porcentajeMaxDescuento: attr('number'),
    // decimalesCostos: attr('number', { defaultValue: 0 }),
    // decimalesCantidades: attr('number', { defaultValue: 0 }),
    // nomformatoOrdenCompra: attr('string'),
    // nomformatoRequisicion: attr('string'),
    // nomformatoEnvio: attr('string'),
    // nomformatoImportacion: attr('string'),
    // nombreBaseDatosOca: attr('string'),
    // codperiodo: attr('string'),
    // cuentaDai: attr('string'),
    // cuentaGasto: attr('string'),
    // fechaNotificacionCorreo: attr('date'),
    // notificacionCorreoMinimos: attr('number', { defaultValue: 0 }),
    activarCasillaCuentaContableCadaKarMovdet: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa')
  });

  _exports.default = _default;
});