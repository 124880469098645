define("sirvo-web/services/current-company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    toast: Ember.inject.service(),
    loader: Ember.inject.service(),
    company: null,
    parameters: null,

    load() {
      const token = this.session.data.authenticated.token;

      if (!token) {
        throw Error('User not authenticated.');
      }

      const authenticator = Ember.getOwner(this).lookup('authenticator:jwt');
      const userData = authenticator.getTokenData(token).username.split('@', 2);

      if (!userData || userData.length < 2) {
        throw Error('Empresa code not found from session data.');
      }

      const codempresa = userData[1];
      this.loader.setLoading(true);
      return this.store.query('empresa', {
        filter: {
          codempresa: {
            exact: codempresa
          }
        }
      }).then(result => {
        if (0 === result.get('length')) {
          throw Error(`Got no record for empresa '${codempresa}'.`);
        }

        if (1 !== result.get('length')) {
          throw Error(`Got more than one record for empresa '${codempresa}'.`);
        }

        this.set('company', result.get('firstObject'));
        return Ember.RSVP.hash({
          company: this.company,
          modulos: this.store.query('modulo', {})
        });
      }).then(({
        company,
        modulos
      }) => {
        let kardexModule = modulos.findBy('directorio', 'KARDEX');

        if (!kardexModule) {
          return company;
        }

        return this.store.query('kar-parametro', {
          filter: {
            empresa: company.id
          }
        }).then(result => {
          if (0 === result.get('length')) {
            throw Error(`Got no 'kar-parametro' for empresa '${company.codempresa}'.`);
          }

          if (1 !== result.get('length')) {
            throw Error(`Got more than one 'kar-parametro' for empresa '${company.codempresa}'.`);
          }

          this.set('parameters', result.get('firstObject'));
          return company;
        });
      }).catch(error => {
        this.toast.error('No es posible acceder en este momento.');
        throw error;
      }).finally(() => this.loader.setLoading(false));
    }

  });

  _exports.default = _default;
});