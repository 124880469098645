define("sirvo-web/routes/inventario/movimientos/salidas/registro-detalle/articulo", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let superData = this._super(...arguments);

      if (!superData.record.get('isNew')) {
        this.toast.error('No puede modificar un registro ya persistido.');
        return this.transitionTo(this.parentRoute);
      }

      return Ember.RSVP.hash({
        articulos: this.store.query('kar-articulo', {
          filter: {
            empresa: this.company.id,
            fechaDebaja: 'NULL'
          },
          sort: 'descripcion'
        })
      }) // WARNING: Using the Ember merge() function gives error.
      // ERROR: You must use set() to set the `articulos` property.
      .then(routeData => {
        Ember.setProperties(superData, routeData);
        return superData;
      });
    }

  });

  _exports.default = _default;
});