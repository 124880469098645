define("sirvo-web/controllers/inventario/movimientos/salidas/traslado/movimiento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    parentCtrl: Ember.inject.controller('inventario.movimientos.salidas.traslado'),
    tipoDocumentoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editTipoDocumento.bind(this)
      }];
    }),
    actions: {
      editTipoDocumento() {
        this.transitionToRoute(`${this.routeName}.tipo-doc`);
      },

      saveMov() {
        return this.parentCtrl.submit();
      }

    }
  });

  _exports.default = _default;
});