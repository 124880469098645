define("sirvo-web/routes/inventario/movimientos/salidas/registro-detalle", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators", "moment"], function (_exports, _abstractModuleRecord, _validators, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    request: Ember.inject.service(),
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar-movdet',
    indexRoute: 'inventario.movimientos.salidas',
    routeRecordIdentifierDynamicSegment: 'detalle_id',
    validations: Ember.computed(function () {
      return {
        empresa: (0, _validators.validatePresence)(true),
        movimiento: (0, _validators.validatePresence)(true),
        articulo: (0, _validators.validatePresence)(true),
        unidadMedida: (0, _validators.validatePresence)(true),
        cantidad: (0, _validators.validateNumber)({
          gt: 0
        })
      };
    }),

    model(params
    /*, transition*/
    ) {
      return this._super(...arguments).then(modelData => {
        return Ember.RSVP.hash({
          movimiento: this.store.findRecord('kar-mov', params.id)
        }).then(routeData => {
          if (modelData.record.get('isNew')) {
            modelData.record.set('movimiento', routeData.movimiento);
          }

          let fecha = (0, _moment.default)(routeData.movimiento.get('fecha'));
          return Ember.RSVP.all([modelData.record.get('articulo'), modelData.record.get('bodega'), modelData.record.get('unidadMedida'), modelData.record.get('cuenta'), modelData.record.get('centroCosto'), modelData.record.get('centroIntegracion')]).then(() => Ember.RSVP.hash({
            periodo: this.store.query('emp-periodo', {
              filter: {
                empresa: this.company.id,
                feciniperiodo: {
                  before: fecha.format('YYYY-MM-DD')
                },
                fecfinperiodo: {
                  after: fecha.format('YYYY-MM-DD')
                }
              }
            })
          })).then(({
            periodo
          }) => {
            let fechaFormatted = fecha.format('DD/MM/YYYY');

            if (periodo.length === 0) {
              this.toast.error(`No se encontró el período fiscal para la fecha ${fechaFormatted}.`);
              return this.transitionTo(this.indexRoute);
            } else if (periodo.length > 1) {
              this.toast.error(`Más de un período fiscal encontrado para la fecha ${fechaFormatted}.`);
              return this.transitionTo(this.indexRoute);
            }

            return Ember.merge(Ember.merge(modelData, routeData), {
              periodo: periodo.get('firstObject')
            });
          }).then(modelData => {
            if (!modelData.record.get('cuenta.id')) {
              return modelData;
            }

            let defNomenclaturaId = modelData.periodo.belongsTo('nomenclatura').id();
            return Ember.RSVP.hash({
              tipoCuentaData: this.request.get(`emp-nomenclaturas/${modelData.record.get('cuenta.id')}/tipo`, {
                defNomenclaturaId
              })
            }).then(({
              tipoCuentaData
            }) => Ember.merge(modelData, {
              tipoCuenta: tipoCuentaData.data.tipo
            }));
          });
        });
      });
    },

    createRecordInstance() {
      return this.store.createRecord(this.modelName, {
        empresa: this.company,
        fecha: new Date()
      });
    },

    setupController() {
      this._super(...arguments);

      if (this.controller.parameters.get('activarCasillaCuentaContableCadaKarMovdet')) {
        this.validations.cuenta = (0, _validators.validatePresence)(true);
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});