define("sirvo-web/templates/inventario/movimientos/salidas/registro-detalle/centro-costo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lkyKg/Ud",
    "block": "{\"symbols\":[\"modal\",\"f\",\"centroCosto\"],\"statements\":[[4,\"bs-modal\",null,[[\"size\",\"onHide\"],[\"lg\",[26,\"action\",[[21,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"\\n  \"],[4,\"component\",[[21,1,[\"body\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[4,\"component\",[[21,2,[\"ace-power-select\"]],\"centroCosto\",\"Centro costo\"],[[\"selected\",\"options\",\"placeholder\",\"searchField\",\"allowClear\",\"onchange\"],[[21,0,[\"changeset\",\"centroCosto\"]],[21,0,[\"model\",\"centrosCosto\"]],\"Seleccionar...\",\"descripcion\",false,[26,\"action\",[[21,0,[]],[26,\"mut\",[[21,0,[\"changeset\",\"centroCosto\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[21,3,[\"codcc\"]],false],[0,\" - \"],[1,[21,3,[\"descripcion\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null],[0,\"  \"]],\"parameters\":[]},null],[0,\"\\n\\n  \"],[4,\"component\",[[21,1,[\"footer\"]]],null,{\"statements\":[[0,\"\\n    \"],[4,\"bs-button\",null,[[\"size\",\"onClick\"],[\"sm\",[26,\"action\",[[21,0,[]],[21,1,[\"close\"]]],null]]],{\"statements\":[[0,\"Cerrar\"]],\"parameters\":[]},null],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/movimientos/salidas/registro-detalle/centro-costo.hbs"
    }
  });

  _exports.default = _default;
});