define("sirvo-web/controllers/admon/grupos/clasificaciones", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "sirvo-web/models/gru-clasificacion"], function (_exports, _abstractModuleIndex, _gruClasificacion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    modelClass: _gruClasificacion.default,
    columns: Ember.computed(function () {
      return [{
        label: 'Código',
        valuePath: 'codclasifica',
        filtrable: true,
        filterName: 'codclasifica',
        width: '100px'
      }, {
        label: 'Descripción',
        valuePath: 'descripcion',
        width: '150px',
        sortable: false
      }, {
        label: 'Grupo',
        valuePath: 'grupo.codgrupo',
        filtrable: true,
        filterName: 'grupo.codgrupo',
        width: '50px'
      }, {
        label: 'Tipo',
        valuePath: 'tipo',
        filtrable: true,
        filterName: 'tipo',
        width: '100px'
      }, {
        label: 'Observaciones',
        valuePath: 'observa',
        width: '150px',
        sortable: false
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        width: '100px',
        sortable: false,
        objectActions: {
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }];
    })
  });

  _exports.default = _default;
});