define("sirvo-web/routes/inventario/movimientos/salidas/registro-detalle/centro-integracion", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    company: Ember.computed.readOnly('currentCompany.company'),

    model() {
      let superData = this._super(...arguments);

      let record = superData.record;

      if (!record.get('isNew')) {
        this.toast.error('No puede modificar un registro ya persistido.');
        return this.transitionTo(this.parentRoute);
      }

      return Ember.RSVP.hash({
        centrosIntegracion: this.store.query('emp-cc', {
          filter: {
            empresa: this.company.id,
            periodo: superData.periodo.id
          }
        })
      });
    }

  });

  _exports.default = _default;
});