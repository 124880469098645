define("sirvo-web/controllers/inventario/movimientos/salidas/traslado", ["exports", "servir-ember-utilities/controllers/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    indexCtrl: Ember.inject.controller('inventario.movimientos.salidas'),
    indexRoute: null,

    postSave(record) {
      if (this.indexCtrl) {
        this.indexCtrl.set('reload', true);
      }

      return this.transitionToRoute(`${this.routeName}.registro`, record.id, 'new');
    },

    actions: {
      onClose() {
        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});