define("sirvo-web/models/kar-articulo", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    codarticulo: (0, _model.attr)('string'),
    // codempresa: attr('string'),
    correlativo: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // codalterno: attr('string'),
    // codtipoart: attr('string'),
    // codigoBarras: attr('string'),
    fechaIngreso: (0, _model.attr)('date'),
    codmedidaBase: (0, _model.attr)('string'),
    // precioVenta: attr('number'),
    // precioMinimo: attr('number'),
    // costo: attr('number'),
    existenciaMin: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    existenciaMax: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    puntoReorden: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // restringir: attr('number', { defaultValue: 0 }),
    // materialIndirecto: attr('number', { defaultValue: 0 }),
    descripcion: (0, _model.attr)('string'),
    // descripcionAdd: attr('string'),
    // procesoFabricacion: attr('string'),
    // car1: attr('string'),
    // car2: attr('string'),
    // car3: attr('string'),
    // car4: attr('string'),
    // car5: attr('string'),
    // capacidad: attr('number'),
    // capacidadNombre: attr('string'),
    // peso: attr('number'),
    // densidad: attr('number'),
    // densidadVariable: attr('number', { defaultValue: 0 }),
    // pesoNombre: attr('string'),
    // observaciones: attr('string'),
    debaja: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    fechaDebaja: (0, _model.attr)('date', {
      defaultValue: () => {
        new Date();
      }
    }),
    // codcuenta: attr('string'),
    // codci: attr('string'),
    // codcc: attr('string'),
    // codcuentaCosto: attr('string'),
    // codciCosto: attr('string'),
    // codccCosto: attr('string'),
    // codcuentaIngreso: attr('string'),
    // codciIngreso: attr('string'),
    // codccIngreso: attr('string'),
    usaLote: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    usaFechaVencimiento: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    // usaMedidasReferencia: attr('number', { defaultValue: 0 }),
    // nombreMedida1: attr('string'),
    // nombreMedida2: attr('string'),
    // nombreMedida3: attr('string'),
    // empaquePrimarioCodarticulo: attr('string'),
    // empaquePrimarioCapacidad: attr('number'),
    // empaquePrimarioCodarticuloEtiqueta: attr('string'),
    // empaquePrimarioCantidadEtiqueta: attr('number'),
    // empaqueSecundarioCodarticulo: attr('string'),
    // empaqueSecundarioCapacidad: attr('number'),
    // empaqueSecundarioCodarticuloEtiqueta: attr('string'),
    // empaqueSecundarioCantidadEtiqueta: attr('number'),
    // empaqueTernarioCodarticulo: attr('string'),
    // empaqueTernarioCapacidad: attr('number'),
    // empaqueTernarioCodarticuloEtiqueta: attr('string'),
    // empaqueTernarioCantidadEtiqueta: attr('number'),
    // resina1: attr('string'),
    // resina2: attr('string'),
    // resina1Porcentaje: attr('number'),
    // resina2Porcentaje: attr('number'),
    // colorante1: attr('string'),
    // colorante2: attr('string'),
    // colorante1Porcentaje: attr('number'),
    // colorante2Porcentaje: attr('number'),
    // aditivo1: attr('string'),
    // aditivo2: attr('string'),
    // aditivo3: attr('string'),
    // aditivo1Porcentaje: attr('number'),
    // aditivo2Porcentaje: attr('number'),
    // aditivo3Porcentaje: attr('number'),
    // cicloFabricacion: attr('number', { defaultValue: 0 }),
    // cantidadMoldesFabricacion: attr('number', { defaultValue: 0 }),
    // cantidadCavidadesPorMoldeFabricacion: attr('number', { defaultValue: 0 }),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    // Relationships
    empresa: (0, _model.belongsTo)('empresa'),
    tipoArticulo: (0, _model.belongsTo)('kar-tipo-articulo'),
    unidadesMedida: (0, _model.hasMany)('kar-tabla-medida'),
    // bodegas: hasMany('kar-bodega-det'),
    codigoDescripcion: Ember.computed('codarticulo', 'descripcion', function () {
      return `${this.codarticulo} - ${this.descripcion}`;
    }),

    save(options) {
      if (this.get('isDeleted')) {
        return this._super(options);
      }

      let removedBodegas = this.bodegas.filterBy('isDeleted');
      let unsavedUnidadesMedida = this.unidadesMedida.filter(uMedida => uMedida.get('isNew') || uMedida.get('hasDirtyAttributes')).filterBy('isValidated');
      let unsavedBodegas = this.bodegas.rejectBy('isDeleted').filter(artBodega => artBodega.get('isNew') || artBodega.get('hasDirtyAttributes'));
      return Ember.RSVP.all(removedBodegas.map(artBodega => artBodega.save())).then(() => this._internalModel.save(options)).then(() => Ember.RSVP.all(unsavedUnidadesMedida.map(uMedida => uMedida.save()))).then(() => Ember.RSVP.all(unsavedBodegas.map(artBodega => artBodega.save()))).then(() => this);
    }

  });

  _exports.default = _default;
});