define("sirvo-web/models/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    SALIDAS_TIPOS_MOVIMIENTOS: [{
      key: 'SALIDAS',
      name: 'Salidas'
    }, {
      key: 'TRASLADOS',
      name: 'Traslados'
    }]
  };
  _exports.default = _default;
});