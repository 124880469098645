define("sirvo-web/templates/inventario/tablas/bodegas/record/datos-generales", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6TpsOsrO",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[1,[26,\"component\",[[21,1,[\"ace-text-field\"]],\"codbodega\",\"Código\"],[[\"placeholder\"],[\"Código\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"descripcion\",\"Descripción\"],[[\"placeholder\"],[\"Descripción\"]]],false],[0,\"\\n\\n  \"],[1,[26,\"component\",[[21,1,[\"ace-textarea-field\"]],\"observaciones\",\"Observaciones\"],[[\"placeholder\"],[\"Observaciones\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/bodegas/record/datos-generales.hbs"
    }
  });

  _exports.default = _default;
});