define("sirvo-web/routes/inventario/movimientos/salidas/registro-detalle/bodega", ["exports", "servir-ember-utilities/mixins/route/is-tab-subroute"], function (_exports, _isTabSubroute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_isTabSubroute.default, {
    model() {
      let superData = this._super(...arguments);

      if (!superData.record.get('isNew')) {
        this.toast.error('No puede modificar un registro ya persistido.');
        return this.transitionTo(this.parentRoute);
      }

      return superData;
    },

    setupController(controller
    /*, model*/
    ) {
      this._super(...arguments);

      let detalleCtrl = this.controllerFor(this.parentRoute);

      if (detalleCtrl) {
        controller.set('bodegas', detalleCtrl.bodegas);
      }
    }

  });

  _exports.default = _default;
});