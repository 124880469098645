define("sirvo-web/templates/inventario/tablas/articulos/record/registro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dfM6oLck",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"extended-form-for\",[[21,0,[\"changeset\"]]],null,{\"statements\":[[0,\"  \"],[6,\"div\"],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[6,\"div\"],[10,\"class\",\"col col-sm-6\"],[8],[0,\"\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"existenciaMin\",\"Existencia mínima\"],[[\"placeholder\"],[\"0\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"existenciaMax\",\"Existencia máxima\"],[[\"placeholder\"],[\"0\"]]],false],[0,\"\\n\\n      \"],[1,[26,\"component\",[[21,1,[\"ace-number-field\"]],\"puntoReorden\",\"Punto de reorden\"],[[\"placeholder\"],[\"0\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "sirvo-web/templates/inventario/tablas/articulos/record/registro.hbs"
    }
  });

  _exports.default = _default;
});