define("sirvo-web/controllers/inventario/movimientos/salidas/traslado/registro", ["exports", "servir-ember-utilities/controllers/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    kardex: Ember.inject.service(),
    bodegas: null,
    unidadesMedida: null,
    existenciaBase: 0,
    existencia: 0,
    postSaveTransitionRoute: Ember.computed.readOnly('indexRoute'),
    articuloActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editArticulo.bind(this)
      }];
    }),
    bodegaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editBodega.bind(this)
      }];
    }),
    loteActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editLote.bind(this)
      }];
    }),
    medidaActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editUnidadMedida.bind(this)
      }];
    }),
    bodegaDestinoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editBodegaDestino.bind(this)
      }];
    }),

    resetProperties() {
      this.setProperties({
        existenciaBase: 0,
        existencia: 0
      });
    },

    processSave(changeset) {
      if (this.existenciaBase - changeset.get('cantidadBase') < 0) {
        this.loader.setLoading(false);
        this.toast.error('No hay suficiente existencia del artículo seleccionado.');
        return Ember.RSVP.resolve(false);
      }

      return this._super(...arguments);
    },

    submit() {
      // TODO: Find a better way to get the current route instance.
      let route = this.target.currentState.routerJs.getHandler(this.routeName);

      if (this.changeset.get('articulo.usaLote')) {
        route.validations.lote = (0, _validators.validatePresence)(true);
      } else {
        delete route.validations.lote;
        delete this.changeset._errors.lote;
      }

      return this._super(...arguments);
    },

    actions: {
      serializeArticulo(articulo) {
        return articulo ? articulo.get('codarticulo') : null;
      },

      serializeBodega(bodega) {
        return bodega ? bodega.get('codbodega') : null;
      },

      serializeUnidadMedida(uMedida) {
        return uMedida ? uMedida.get('codmedida') : null;
      },

      editArticulo() {
        this.transitionToRoute(`${this.routeName}.articulo`);
      },

      editBodega() {
        this.transitionToRoute(`${this.routeName}.bodega`);
      },

      editLote() {
        this.transitionToRoute(`${this.routeName}.lote`);
      },

      editUnidadMedida() {
        this.transitionToRoute(`${this.routeName}.unidad-medida`);
      },

      editBodegaDestino() {
        if (!this.changeset.get('bodega.id')) {
          this.toast.error('Seleccione primer la bodega de origen.');
          return;
        }

        this.transitionToRoute(`${this.routeName}.bodega-destino`);
      },

      updateCantidad(changeset, properyName, value) {
        changeset.set(properyName, parseFloat(value) || 0);
        this.kardex.onChangesetCantidadUpdated(changeset);
      },

      cancelEdit() {
        // The route will destroy the record if it's new.
        this.transitionToRoute(this.indexRoute);
      }

    }
  });

  _exports.default = _default;
});