define("sirvo-web/controllers/inventario/movimientos/ingresos/record/articulo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      onClose() {
        this.transitionToRoute(this.parentRoute);
      },

      selectArticulo(articulo) {
        this.store.query('kar-bodega-det', {
          filter: {
            articulo: articulo.get('id')
          }
        }).then(bodegasDet => {
          Ember.set(this, 'bodegas', bodegasDet.map(bodegaDet => {
            return bodegaDet.bodega;
          }));
        });
      }

    }
  });

  _exports.default = _default;
});