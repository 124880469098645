define("sirvo-web/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    loader: Ember.inject.service(),
    breadcrumbs: null,
    isLoading: Ember.computed.readOnly('loader.loading'),
    isAuthenticated: Ember.computed('session.isAuthenticated', function () {
      return this.session.get('isAuthenticated');
    }),
    actions: {
      refreshBreadCrumbs(newBreadcrumbs) {
        this.set('breadcrumbs', newBreadcrumbs);
      }

    }
  });

  _exports.default = _default;
});