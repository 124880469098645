define("sirvo-web/models/gru-directorio", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    coddirectorio: (0, _model.attr)('string'),
    // codgrupo: attr('string'),
    codaca: (0, _model.attr)('string'),
    nombredirectorio: (0, _model.attr)('string'),
    razonsocial: (0, _model.attr)('string'),
    nit: (0, _model.attr)('string'),
    id_interno: (0, _model.attr)('string'),
    no_dpi: (0, _model.attr)('string'),
    // sucursal_mb: attr('number'),
    persona_individual_juridica: (0, _model.attr)('number'),
    esextranjero: (0, _model.attr)('number', {
      defaultValue: 0
    }),
    esagenteretiva: (0, _model.attr)('number'),
    pagaretiva: (0, _model.attr)('number'),
    codretiva: (0, _model.attr)('string'),
    esagenteretisr: (0, _model.attr)('number'),
    pagaretisr: (0, _model.attr)('number'),
    codretisr: (0, _model.attr)('string'),
    usaexencioniva: (0, _model.attr)('number'),
    // noregistroiva: attr('string'),
    tipo_iva_directorio: (0, _model.attr)('string'),
    // giroempresa: attr('string'),
    // tipocontri: attr('string'),
    // catecontri: attr('string'),
    // consumidorfinal: attr('number'),
    fechacreacion: (0, _model.attr)('date'),
    // es_inactivo: attr('number'),
    fechabaja: (0, _model.attr)('date'),
    // coduser_inactivo: attr('string'),
    dias_cr_cxp: (0, _model.attr)('number'),
    dias_cr_cxc: (0, _model.attr)('number'),
    tiene_credito: (0, _model.attr)('boolean'),
    // limite_cr_cxc: attr('number'),
    // lista_precios: attr('number'),
    // usavendedor: attr('number'),
    // codvendedor: attr('string'),
    // nomvendedor: attr('string'),
    // codruta: attr('string'),
    // codruta_cobro: attr('string'),
    // usa_sucursales: attr('number'),
    observa: (0, _model.attr)('string'),
    direccion: (0, _model.attr)('string'),
    direccion2: (0, _model.attr)('string'),
    codigopostal: (0, _model.attr)('string'),
    // codpais: attr('string'),
    // coddepto: attr('string'),
    // codzona: attr('string'),
    // codmuni: attr('string'),
    telefonooficina: (0, _model.attr)('string'),
    telefonooficina2: (0, _model.attr)('string'),
    telefonocasa: (0, _model.attr)('string'),
    telefonomovil: (0, _model.attr)('string'),
    // telefonobeeper: attr('string'),
    telefonofax: (0, _model.attr)('string'),
    email: (0, _model.attr)('string'),
    email2: (0, _model.attr)('string'),
    email3: (0, _model.attr)('string'),
    email_publicidad: (0, _model.attr)('string'),
    webpage: (0, _model.attr)('string'),
    // foto: attr('string'),
    // cumple_dia: attr('number'),
    // cumple_mes: attr('number'),
    // codsolicitud: attr('string'),
    // fecha_autoriza_solicitud: attr('date'),
    // coduser_autoriza_solicitud: attr('string'),
    // documentado: attr('boolean'),
    enletras_en_ingles: (0, _model.attr)('boolean'),
    usa_tdc_especial: (0, _model.attr)('boolean'),
    cxc_envio_correos_masivos: (0, _model.attr)('boolean'),
    cxp_envio_correos_masivos: (0, _model.attr)('boolean'),
    // origen: attr('string'),
    // fac_nombre: attr('string'),
    // fac_nit: attr('string'),
    // fac_direccion: attr('string'),
    chq_nombre: (0, _model.attr)('string'),
    // pi_profesion: attr('string'),
    // pi_grupo: attr('string'),
    // pi_categoria: attr('string'),
    // pi_relacion: attr('string'),
    // pi_empresa_labora: attr('string'),
    // pi_empresa_labora_direccion: attr('string'),
    // pi_estatus_cuenta: attr('string'),
    // pi_locker_valor: attr('number'),
    // pi_cuota: attr('number'),
    // pi_fecha_ingreso: attr('date'),
    // pi_fecha_nacimiento: attr('date'),
    // pi_estado_civil: attr('string'),
    // pi_contacto: attr('string'),
    // pi_contacto_telefono: attr('string'),
    // pi_observaciones_adicionales: attr('string'),
    // tarj_credito_numero: attr('string'),
    // tarj_credito_vence: attr('string'),
    // tarj_credito_cobro_recurrente: attr('boolean'),
    // tarj_credito_monto_max_debito: attr('number'),
    // tarj_credito_fecha_aplica: attr('date'),
    // tarj_credito_email_notif: attr('string'),
    facebook: (0, _model.attr)('string'),
    whatsapp_numero: (0, _model.attr)('string'),
    cliente_tipo: (0, _model.attr)('string'),
    codigo_tributario: (0, _model.attr)('string'),
    codigo_cai: (0, _model.attr)('string'),
    fecha_actualizacion: (0, _model.attr)('date'),
    creusr: (0, _model.attr)('string'),
    fecusr: (0, _model.attr)('date'),
    codusr: (0, _model.attr)('string'),
    stausr: (0, _model.attr)('string'),
    // Relationships
    grupo: (0, _model.belongsTo)('grupo'),
    pais: (0, _model.belongsTo)('com-pais'),
    depto: (0, _model.belongsTo)('com-codigo-postal-depto'),
    muni: (0, _model.belongsTo)('com-codigo-postal-muni'),
    zona: (0, _model.belongsTo)('com-codigo-postal-zona'),
    codigoRazonSocial: Ember.computed('coddirectorio', 'razonsocial', function () {
      return `${this.coddirectorio || ''} - ${this.razonsocial || ''}`;
    })
  });

  _exports.default = _default;
});