define("sirvo-web/routes/inventario/movimientos/ingresos/cabecera", ["exports", "servir-ember-utilities/routes/abstract-module-record"], function (_exports, _abstractModuleRecord) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { validatePresence } from 'ember-changeset-validations/validators';
  var _default = _abstractModuleRecord.default.extend({
    company: Ember.computed.readOnly('currentCompany.company'),
    modelName: 'kar_mov',
    indexRoute: 'inventario.movimientos.ingresos',

    // validations: computed(function () {
    //   return {
    //     empresa: validatePresence({ presence: true }),
    //     serie: validatePresence({ presence: true }),
    //     numero: validatePresence({ presence: true }),
    //   };
    // }),
    // relatedModelsToQuery: computed(function () {
    //   let relatedModels = [];
    //   relatedModels.push(
    //     {
    //       name: 'karArticulos',
    //       modelName: 'kar-articulo'
    //     },
    //     {
    //       name: 'proveedores',
    //       modelName: 'gru-directorio',
    //       queryParams: {
    //         filter: {
    //           'clasificaciones.clasificacion.tipo': 'PROVEEDORES'
    //         }
    //       }
    //     }
    //   );
    //   return relatedModels;
    // }),
    createRecordInstance() {
      return this.store.createRecord(this.get('modelName'), {
        empresa: this.company
      });
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});